// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/plus.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media (max-width: 575px) {\ninput[data-v-3ab21381] {\n    width: 100%;\n}\n}\n@media (min-width: 768px) {\n.form-input input[data-v-3ab21381] {\n    width: 331px;\n}\n}\n.block-label > label[data-v-3ab21381] {\n  font-family: \"Roboto\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  display: block;\n  font-size: 14px;\n  color: #a7a7a7;\n  margin: 12px 0px;\n  width: auto;\n}\n.line-item[data-v-3ab21381] {\n  padding: 0px 30px;\n  margin-right: 0;\n}\n.label-offset[data-v-3ab21381] {\n  margin-left: 155px;\n}\n.label-offset.line-item[data-v-3ab21381] {\n  margin-right: -30px;\n}\n.label-offset.line-item[data-v-3ab21381]:before {\n  display: none;\n}\n.add-new[data-v-3ab21381] {\n  vertical-align: middle;\n  align-items: center;\n  display: flex;\n  cursor: pointer;\n}\n.add-new__img[data-v-3ab21381] {\n  width: 18px;\n  height: 18px;\n  background-position: right center;\n  background-color: #93BD20;\n  -webkit-mask-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n          mask-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  -webkit-mask-repeat: no-repeat;\n          mask-repeat: no-repeat;\n  -webkit-mask-size: 15px 19px;\n          mask-size: 15px 19px;\n}\n.add-new__label[data-v-3ab21381] {\n  height: 21px;\n  width: 115px;\n  padding-left: 3px;\n  color: #72980a;\n  font-family: Roboto;\n  font-size: 14px;\n  font-weight: bold;\n  line-height: 21px;\n}\n.scroller[data-v-3ab21381] {\n  height: 100%;\n  overflow-y: auto;\n}", ""]);
// Exports
module.exports = exports;
